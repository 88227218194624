import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  heading: '',
  description: '',
  closeButtonText: 'Cancel',
  actionButtonText: 'Delete',
  action: () => null,
};

export const closeActionModal = createAsyncThunk('modal/close', async () => {
  window.deleteModal.close();
  return await new Promise((resolve) => setTimeout(() => resolve(), 150));
});

const confirmationModalSlice = createSlice({
  name: 'confirmationModal',
  initialState,
  reducers: {
    openActionModal: (state, action) => {
      window.deleteModal.showModal();

      return {
        ...state,
        heading: action.payload.heading,
        description: action.payload.description,
        closeButtonText: action.payload.closeButtonText ?? 'Cancel',
        actionButtonText: action.payload.actionButtonText ?? 'Delete',
        action: action.payload.action,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeActionModal.fulfilled, (state) => {
      return {
        ...state,
        heading: '',
        action: null,
      };
    });
  },
});
export const { openActionModal } = confirmationModalSlice.actions;

export default confirmationModalSlice.reducer;
