import React from 'react';
import { Link } from 'react-router-dom';

const LayoutNavItem = ({ title, isActive, href }) => {
  return (
    <Link
      className={`rounded-md py-[5px] px-3 text-sm text-gray-1 border border-transparent hover:bg-background-header ${
        isActive ? 'text-primary !bg-background shadow-[0px_1px_2px_0px_#0000000D] border-gray-2' : ''
      }`}
      to={href}>
      {title}
    </Link>
  );
};

export default LayoutNavItem;
