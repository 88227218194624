import React from 'react';

const Tooltip = ({ infoData, className, bodyClassName }) => {
  infoData.tooltip = infoData.tooltip || <span className='material-symbols-outlined text-large text-primary cursor-help'>info</span>;
  return (
    <div className={`tooltip ${className} cursor-help`}>
      <div className={`
        tooltip-body py-2 px-3 !bg-gray-1 absolute text-white text-xs invisible rounded-lg z-50
        ${infoData.position ? infoData.position : 'tooltip-bottom'}
        ${bodyClassName}`
      }>
        {infoData?.title && <p className='!text-xs font-semibold border-border'>{infoData?.title}</p>}
        
        <div>{infoData?.content}</div>
        
        {infoData.warning && (
          <div className='flex items-center gap-1 p-2'>
            <p className='material-symbols-outlined text-danger filled-icon'>warning</p>
            <p className='text-danger'>{infoData?.warning}</p>
          </div>
        )}
      </div>

      {infoData.tooltip}
    </div>
  );
};

export default Tooltip;
