import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  users: [],
  user: {},
  totalPage: null,
  totalCount: null,
  isLoading: true,
  error: null,
};

export const getUsers = createAsyncThunk('users/getUsers', async (params) => {
  const response = await handleAPI('platform/users', 'get', params);
  return response;
});

export const getUser = createAsyncThunk('users/getUser', async (id) => {
  const response = await handleAPI(`platform/users/${id}`, 'get');
  return response;
});

export const addUser = createAsyncThunk('users/addUser', async (data) => {
  const response = await handleAPI('/crud', 'post', '', data);
  return response;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    deleteUser: (state, action) => {
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
    });
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(addUser.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });
    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { deleteUser } = userSlice.actions;

export default userSlice.reducer;
