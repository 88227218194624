import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideToastr } from '../../../redux/slices/toastrSlice';
import ToastrCheckIcon from '../../icons/ToastrCheckIcon';

const Toastr = () => {
  const dispatch = useDispatch();
  const toastrList = useSelector((state) => state.toastr.toastrList);
  const [hoveredToastr, setHoveredToastr] = useState(undefined);

  useEffect(() => {
    toastrList.forEach((toastr, index) => {
      const timer = setTimeout(() => {
        if (index !== hoveredToastr) {
          dispatch(hideToastr(index));
        }
      }, 3500 * (index + 1));
      return () => clearTimeout(timer);
    });
  }, [toastrList, dispatch, hoveredToastr]);

  const getColors = (type) => {
    return type === 'error'
      ? 'bg-state-red-background text-state-red [&_path]:fill-state-red before:bg-state-red'
      : 'bg-state-green-background text-state-green [&_path]:fill-text-state-green before:bg-state-green';
  };

  const handleMouseEnter = (index) => {
    setHoveredToastr(index);
  };

  const handleMouseLeave = () => {
    setHoveredToastr(null);
  };

  return (
    <div className='fixed flex flex-col gap-2 top-3 md:top-20 items-center w-screen !z-[1000]'>
      {toastrList.map((toastr, index) => (
        <div
          key={index}
          className={`
            relative flex items-center max-w-[600px] mx-4 justify-between gap-2 p-3 rounded-r-md rounded-l-[4px] font-semibold text-sm
            before:content-[''] before:absolute before:-z-[1] before:top-0 before:right-0 before:rounded-lg before:h-full before:w-[calc(100%+5px)]
            ${getColors(toastr.type)}
          `}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}>
          <ToastrCheckIcon svgClassName='min-w-max min-h-max' />
          <p>{toastr.message}</p>
        </div>
      ))}
    </div>
  );
};

export default Toastr;
