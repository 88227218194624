import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import paths from '../../../constants/paths';
import LayoutWithNav from '../LayoutWithNav';
import { getProduct } from '../../../redux/slices/product/productSlice';
import { getOptionValues } from '../../../redux/slices/product/optionValuesSlice';
import { useDispatch } from 'react-redux';


const ProductEditLayout = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getProduct({
        id: params?.id,
        params: { include: 'option_types,variants_including_master,vertical,primary_variant' },
      }),
    );

    dispatch(
      getOptionValues({
        include: `option_values`,
        'filter[product_option_types_product_id_eq]': params.id,
      }),
    );
  }, [dispatch, params?.id]);

  const items = [
    { title: 'General details', href: paths.editProduct(params.id) },
    { title: 'Variants', href: paths.variants(params.id) },
  ];

  return (
    <LayoutWithNav
      pageTitle='Edit product'
      navItems={items}
    />
  );
};

export default ProductEditLayout;
