import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Toastr from './components/atoms/toasts/Toastr';
import useAxiosPrivate from './hooks/auth/useAxiosPrivate';
import ConfirmationModal from './components/molecule/modal/ConfirmationModal';
import { router } from './constants/routes';
import { getCookie } from './utils/helper/helper';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createTheme, ThemeProvider } from '@mui/material';

function App() {
  useAxiosPrivate();
  const { theme } = useSelector((state) => state.theme);
  const { role } = useSelector((state) => state.auth);
  const { abilities } = useSelector((state) => state.auth);


  const muiTheme = createTheme({
    typography: {
      fontFamily: [
        'Inter',
        'sans-serif'
      ].join(','),
      fontSize: 14
    },
  });


  return (
    <ThemeProvider theme={muiTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div
          id='body'
          data-theme={theme}
          className={`${getCookie('theme') === 'dark' && 'dark'}`}>
          <RouterProvider
            router={router(role, abilities)}
            fallbackElement={<>loading...</>}
          />
          <Toastr />
          <ConfirmationModal />
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
