import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  webhooks: [],
  webhook: {},
  logs: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getWebhooks = createAsyncThunk('webhook/getWebhooks', async (params) => {
  const response = await handleAPI('platform/webhooks', 'get', params);
  return response;
});

export const getWebhook = createAsyncThunk('webhook/getWebhook', async ({ id, params }) => {
  const response = await handleAPI(`platform/webhooks/${id}`, 'get', params);
  return response;
});

export const getWebhookLogs = createAsyncThunk('webhook/getWebhookLogs', async (params) => {
  const response = await handleAPI('platform/webhook_logs', 'get', params);
  return response;
});

const webookSlice = createSlice({
  name: 'webhook',
  initialState,
  reducers: {
    deleteWebhook: (state, action) => {
      state.webhooks = state.webhooks.filter((webhook) => webhook.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWebhooks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWebhooks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.webhooks = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getWebhooks.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getWebhook.fulfilled, (state, action) => {
      state.isLoading = false;
      state.webhook = action.payload;
    });
    builder.addCase(getWebhookLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.logs = action.payload;
    });
  },
});

export const { deleteWebhook } = webookSlice.actions;

export default webookSlice.reducer;
