const userPaths = {
  users: '/admin/users',
  newUser: '/admin/users/new',
  editUser: (id = ':id') => `/admin/users/${id}/edit`,
  logs: '/admin/logs',
  plugins: '/admin/plugins',
  smtp: '/admin/plugins/smtp',
  fulfilmentHouse: '/admin/plugins/fulfilment-house',
  newSmtp: '/admin/plugins/smtp/new',
  newFulfilmentHouse: '/admin/plugins/fulfilment-house/new',
  editSmtp: (id = ':id') => `/admin/plugins/smtp/${id}/edit`,
  editFulfilmentHouse: (id = ':id') => `/admin/plugins/fulfilment-house/${id}/edit`,
  affiliates: '/admin/affiliate',
  adminAffiliates: '/admin/affiliate/affiliates',
  editAffiliate: (id = ':id') => `/admin/affiliate/affiliates/${id}/edit`,
  scrubSettings: '/admin/affiliate/scrub-settings',
  pixels: (id = ':id') => `/admin/affiliate/affiliates/${id}/edit/pixels`,
  publishers: (id = ':id') => `/admin/affiliate/affiliates/${id}/edit/publishers`,
  affiliateCampaigns: (id = ':id') => `/admin/affiliate/affiliates/${id}/edit/campaigns`,
  affiliateScrub: (id = ':id') => `/admin/affiliate/affiliates/${id}/edit/publisher-scrub`,
  scrubSetting: (id = ':id') => `/admin/affiliate/affiliates/${id}/edit/scrub-setting`,
  blacklists: '/admin/blacklists',
  whitelists: '/admin/whitelists',
  testCards: '/admin/test-cards',
  webhooks: '/admin/webhooks',
  newWebhook: '/admin/webhooks/new',
  editWebhook: (id = ':id') => `/admin/webhooks/${id}/edit`,
};
export default userPaths;
