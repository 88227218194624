import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  customers: [],
  customer: {},
  totalPages: null,
  totalCount: null,
  isLoading: true,
  error: null,
};

export const getCustomers = createAsyncThunk('customers/getCustomers', async (params) => {
  const response = await handleAPI('platform/customers', 'get', params);
  return response;
});

export const getCustomer = createAsyncThunk('customers/getCustomer', async ({ id, params }) => {
  const response = await handleAPI(`platform/customers/${id}`, 'get', params);
  return response;
});

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    deleteCustomer: (state, action) => {
      state.customers = state.customers.filter((customer) => customer.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customers = action.payload;
      state.totalPages = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
    });
    builder.addCase(getCustomers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customer = action.payload;
    });
    builder.addCase(getCustomer.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { deleteCustomer } = customerSlice.actions;

export default customerSlice.reducer;
