import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  recentActivity: [],
  totalPages: null,
  totalCount: null,
  isLoading: true,
  error: null,
};

export const getRecentActivity = createAsyncThunk(
  'recentActivity/getRecentActivity',
  async (params) => {
    const response = await handleAPI(
      'platform/activity/access_logs/for_current_user',
      'GET',
      params
    );
    return response;
  }
);

const recentActivitySlice = createSlice({
  name: 'recentActivity',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRecentActivity.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRecentActivity.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recentActivity = action.payload;
      state.totalPages = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
    });
    builder.addCase(getRecentActivity.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default recentActivitySlice.reducer;
