import React, { Suspense, lazy } from 'react';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';
const Customers = lazy(() =>
  import('../../components/pages/customer/Customers'),
);
const OrderEntry = lazy(() =>
  import('../../components/pages/customer/OrderEntry'),
);
const Customer = lazy(() =>
  import('../../components/pages/customer/customerDetails/Customer'),
);

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

const createRoute = (path, Component) => ({
  path,
  element: createLazyComponent(Component),
});

export const customerRoutes = (abilities) => {
  return [
    (abilities?.customer_service?.read ?? false) && createRoute(paths.customers, Customers),
    (abilities?.customer_service?.read ?? false) && createRoute(paths.customer(), Customer),
    (abilities?.customer_service?.read ?? false) && createRoute(paths.orderEntry, OrderEntry),
  ];
};
