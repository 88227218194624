const URL = window.location.href;

const PROTOCOL = URL.split('//')[0];
const SUBDOMAIN = URL.split('//')[1].split('.')[0];

export let BACKEND_URL;
if (process.env.REACT_APP_ENV === 'production') {
  BACKEND_URL = `${PROTOCOL}//${SUBDOMAIN}${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_API_BASE_PATH}`;
} else {
  BACKEND_URL = `${PROTOCOL}//${SUBDOMAIN}.${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_API_BASE_PATH}`;
}
