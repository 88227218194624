import React, { Suspense, lazy } from 'react';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';
import ProductEditLayout from '../../components/layout/product/ProductEditLayout';

const ProductLayout = lazy(() =>
  import('../../components/layout/product/ProductLayout'),
);
const Product = lazy(() => import('../../components/pages/product/products'));
const AddProduct = lazy(() =>
  import('../../components/pages/product/products/AddProduct'),
);
const Variants = lazy(() =>
  import('../../components/pages/product/products/variant/Variants'),
);
const ProductSettings = lazy(() =>
  import('../../components/pages/product/settings/ProductSettings'),
);

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

const createRoute = (path, Component) => ({
  path,
  element: createLazyComponent(Component),
});

export const productRoutes = (abilities) => {
  return [
    {
      path: paths.getProductLayout,
      element: createLazyComponent(ProductLayout),
      children: [
        (abilities?.product?.read ?? false) && createRoute(paths.products, Product),
        (abilities?.vertical?.read ?? false) && createRoute(paths.productSettings, ProductSettings),
      ],
    },
    {
      path: paths.getProductEditLayout(),
      element: createLazyComponent(ProductEditLayout),
      children: [
        (abilities?.product?.write ?? false) && createRoute(paths.editProduct(), AddProduct),
        (abilities?.product?.read ?? false) && createRoute(paths.variants(), Variants),
      ],
    },
  ];
};
