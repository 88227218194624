import { createSlice } from '@reduxjs/toolkit';
import { getCookie, setCookie } from '../../utils/helper/helper';

const initialState = {
  theme: getCookie('theme') ?? 'light',
  isLoading: true,
  error: null,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      setCookie('theme', action.payload);

      return {
        ...state,
        theme: action.payload,
      }
    },
  },
});
export const { changeTheme } = themeSlice.actions;

export default themeSlice.reducer;
