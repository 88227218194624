import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  autoReruns: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getAutoReruns = createAsyncThunk('autoreruns/getAutoReruns', async (params) => {
  const response = await handleAPI('platform/auto_reruns', 'get', params);
  return response;
});

const autoRerunSlice = createSlice({
  name: 'autoReruns',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAutoReruns.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAutoReruns.fulfilled, (state, action) => {
      state.isLoading = false;
      state.autoReruns = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getAutoReruns.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default autoRerunSlice.reducer;
