import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  whitelists: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getWhitelists = createAsyncThunk('whitelist/getWhitelists', async (params) => {
  const response = await handleAPI('platform/white_lists', 'get', params);
  return response;
});

const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState,
  reducers: {
    deleteWhiteList: (state, action) => {
      state.whitelists = state.whitelists.filter((whitelist) => whitelist.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWhitelists.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWhitelists.fulfilled, (state, action) => {
      state.isLoading = false;
      state.whitelists = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getWhitelists.rejected, (state, action) => {
      state.isLoading = false;
      state.totalPage = 0;
      state.totalCount = 0;
      state.count = 0;
      state.error = action.payload;
    });
  },
});

export const { deleteWhiteList } = whitelistSlice.actions;

export default whitelistSlice.reducer;
