import React from 'react';
import PropTypes from 'prop-types';

const ModalTitle = ({ title, className, ...props }) => {
  return (
    <h3
      className={`text-xl font-bold textcolor ${className}`}
      {...props}>
      {title}
    </h3>
  );
};

ModalTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default ModalTitle;
