import React, { useEffect } from 'react';
import Button from '../../atoms/button/Button';
import { useNavigate } from 'react-router-dom';
import paths from '../../../constants/paths';
import { getCookie } from '../../../utils/helper/helper';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';

const NotFound = () => {
  const navigate = useNavigate();
  const token = getCookie('token');

  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [navigate, token]);

  const goBack = () => {
    window.history.state && window.history.state.idx > 0 ? navigate(-1) : navigate('/');
  };

  return (
    <div className='h-screen flex flex-col p-3 gap-5 justify-center items-center text-center'>

      <p className="text-blue font-bold">404 error</p>

      <p className="text-4xl font-bold">{'We can\'t find that page'}</p>

      <p className="text-gray-1">{'Sorry, the page you are looking for doesn\'t exist or has been moved.'}</p>

      <div className="gap-5 mt-6 flex flex-col md:flex-row items-center">
        <Button
          variant='ghost'
          icon={<ArrowLeftIcon />}
          title='Go back'
          onClickHandler={goBack}
        />

        <Button
          title='Take me home'
          onClickHandler={() => navigate('/')}
        />
      </div>
    </div>
  );
};

export default NotFound;
