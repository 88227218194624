import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ title, className, ...props }) => {
  return (
    <h2
      className={`text-2xl font-bold textcolor ${className}`}
      {...props}>
      {title}
    </h2>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default PageTitle;
