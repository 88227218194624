import React from 'react'
import LayoutNavItem from '../../atoms/LayoutNavItem/LayoutNavItem';

const LayoutNav = ({ items }) => {
  const { pathname } = window.location;

  return (
    <div className='flex flex-wrap md:flex-col p-[2px] rounded-lg bg-gray-3 h-max md:w-max'>
      {items.map((item, index) => (
        <LayoutNavItem
          key={index}
          title={item.title}
          href={item.href}
          isActive={pathname.startsWith(item.href)}
        />
      ))}
    </div>
  );
};

export default LayoutNav;
