import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  defaultMapper: [],
  isLoading: true,
  totalPage: null,
  totalCount: null,
  count: null,
  error: null,
};

export const getDefaultMappers = createAsyncThunk('default/getDefaultMappers', async (params) => {
  const response = await handleAPI('platform/campaign_category_gateway_mappers', 'get', params);
  return response;
});

const defaultMapperSlice = createSlice({
  name: 'defaultMapper',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDefaultMappers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDefaultMappers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.defaultMapper = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getDefaultMappers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default defaultMapperSlice.reducer;
