import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  verticals: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getVerticals = createAsyncThunk(
  'verticals/getVerticals',
  async (params) => {
    const response = await handleAPI('/platform/verticals', 'get', params);
    return response;
  },
);

const verticalsSlice = createSlice({
  name: 'verticals',
  initialState,
  reducers: {
    editVertical: (state, action) => {
      const updatedRecord = action.payload;
      const verticals = state.verticals.map((vertical) =>
        vertical.id === updatedRecord.id ? updatedRecord : vertical,
      );

      return {
        ...state,
        verticals,
        isLoading: false,
      };
    },
    addVertical: (state, action) => {
      return {
        ...state,
        verticals: [action.payload, ...state.verticals],
        isLoading: false,
      };
    },
    deleteVertical: (state, action) => {
      const id = action.payload;
      const verticals = state.verticals.filter((vertical) => vertical.id !== id);

      return {
        ...state,
        verticals,
        isLoading: false,
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getVerticals.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getVerticals.fulfilled, (state, action) => {
      return {
        ...state,
        verticals: action.payload,
        totalPage: action.metaData?.total_pages,
        isLoading: false,
      };
    });
    builder.addCase(getVerticals.rejected, (state, action) => {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    });
  },
});
export const { editVertical, addVertical, deleteVertical } = verticalsSlice.actions;

export default verticalsSlice.reducer;
