const campaignPaths = {
  campaigns: '/campaign',
  campaignSettings: '/campaign/settings',
  editCampaign: (id = ':id') => `/campaign/${id}/general-details`,
  countries: (id = ':id') => `/campaign/${id}/countries`,
  offers: (id = ':id') => `/campaign/${id}/offers`,
  newOffer: (id = ':id') => `/campaign/${id}/offers/new`,
  editOffer: (id = ':id', offerID = ':offerID') => `/campaign/${id}/offers/${offerID}/edit`,
  upsells: (id = ':id') => `/campaign/${id}/upsells`,
  newUpsell: (id = ':id') => `/campaign/${id}/upsells/new`,
  editUpsell: (id = ':id', upsellID = ':upsellID') => `/campaign/${id}/upsells/${upsellID}/edit`,
  fulfilments: (id = ':id') => `/campaign/${id}/fulfilments`,
  campaignAffiliates: (id = ':id') => `/campaign/${id}/affiliates`,
  newAffiliate: (id = ':id') => `/campaign/${id}/affiliates/new`,
  EditAffiliate: (id = ':id', affiliateID = ':affiliateID') => `/campaign/${id}/affiliates/${affiliateID}`,
  emails: (id = ':id') => `/campaign/${id}/emails`,
  newEmail: (id = ':id') => `/campaign/${id}/emails/new`,
  editEmail: (id = ':id',emailId= ':emailId') => `/campaign/${id}/emails/${emailId}/edit`,
  campaingPixels: (id = ':id') => `/campaign/${id}/pixels`,
  newCampaignPixel: (id = ':id') => `/campaign/${id}/pixels/new`,
  editCampaignPixel: (id = ':id',pixelId= ':pixelId') => `/campaign/${id}/pixels/${pixelId}/edit`,
};

export default campaignPaths;
