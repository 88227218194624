import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  rebillPools: [],
  rebillPool: {},
  rebillPoolCorporations: [],
  totalPagePools: null,
  totalPageCorporations: null,
  totalCountPools: null,
  totalCountCorporations: null,
  countPools: null,
  countCorporations: null,
  isLoading: true,
  error: null,
};

export const getRebillPools = createAsyncThunk('pools/getRebillPools', async (params) => {
  const response = await handleAPI('/platform/rebill_pools', 'get', params);
  return response;
});

export const getRebillPool = createAsyncThunk('pools/getRebillPool', async ({ id, params }) => {
  const response = await handleAPI(`/platform/rebill_pools/${id}`, 'get', params);
  return response;
});

export const getRebillPoolCorporation = createAsyncThunk('pools/getRebillPoolCorporation', async ({ id, params }) => {
  const response = await handleAPI(`platform/rebill_pools/${id}/corporations`, 'get', params);
  return response;
});

const rebillPoolSlice = createSlice({
  name: 'rebillPools',
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getRebillPools.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRebillPools.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rebillPools = action.payload;
      state.totalPagePools = action?.metaData?.total_pages;
      state.totalCountPools = action?.metaData?.total_count;
      state.countPools = action?.metaData?.count;
    });
    builder.addCase(getRebillPools.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getRebillPool.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRebillPool.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rebillPool = action.payload;
    });
    builder.addCase(getRebillPool.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getRebillPoolCorporation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRebillPoolCorporation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rebillPoolCorporations = action.payload;
      state.totalPageCorporations = action?.metaData?.total_pages;
      state.totalCountCorporations = action?.metaData?.total_count;
      state.countCorporations = action?.metaData?.count;
    });
    builder.addCase(getRebillPoolCorporation.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default rebillPoolSlice.reducer;
